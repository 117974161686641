import React from "react"
import { Col, Container } from "react-bootstrap"
import Slide from "../../utility/slide_content"
import SVGIcon from "../SVGIcon"
import "./Flowchart.scss"

const Flowchart = props => {
  const { advantagesHeading, advantagesExcerpt, iconBgColor } = props.data
  return (
    <div
      className="flowchart"
      style={{
        background:
          iconBgColor.length &&
          `linear-gradient(90deg, rgba(255, 255, 255, 0.03) 15%, ${iconBgColor[0].hex} 40%, rgba(255, 255, 255, 0.00) 85%`,
      }}
    >
      <Slide delay="200">
        <Container className="Layout-container">
          <Col lg={7} className="mx-auto">
            {advantagesHeading.length && (
              <h2 className="font-roboto text-center fw-bold title">
                {advantagesHeading[0]}
              </h2>
            )}
          </Col>
          <div className="d-flex flex-wrap chart-wrapper">
            {advantagesExcerpt.map((item, id) => {
              return (
                <div className="d-flex flex-column align-items-center prelative child">
                  <SVGIcon name={`flowchart/colorNo${id + 1}`} />
                  {id < 3 && (
                    <SVGIcon
                      name={`flowchart/arrowDsk${id + 1}`}
                      className="position-absolute arrows d-dsk"
                    />
                  )}
                  {(id === 0 || id === 2) && (
                    <SVGIcon
                      name={`flowchart/arrowDsk${id === 0 ? 1 : 2}`}
                      className="position-absolute arrows d-mob"
                    />
                  )}
                  <h5 className="fw-600 mt32 text-center name font-roboto">
                    {item}
                  </h5>
                </div>
              )
            })}
          </div>
        </Container>
      </Slide>
    </div>
  )
}

export default Flowchart
