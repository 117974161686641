import React from "react"
import { Container } from "react-bootstrap"
import Slide from "../../utility/slide_content"
import Buttons from "../button"
import "./EbookDownload.scss"

const EbookDownload = props => {
  const { cardName, cardDescription, slug, image, text } = props.data
  return (
    <Slide delay="200">
      <Container className="ebook-card">
        <div className="d-flex parent justify-content-between">
          <div className="altv3 p16 content w-100">
            <h3 className="font-roboto heading fw-bold mb16">{cardName}</h3>
            <p className="description m-0">{cardDescription}</p>
            <Buttons theme="secondary-new mt40" link={slug} text={text} />
          </div>
          <div className="img-wrapper">
            <img src={image[0].url} alt={cardName} className="w-100" />
          </div>
        </div>
      </Container>
    </Slide>
  )
}

export default EbookDownload
