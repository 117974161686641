import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Buttons from "../button"
import IntegrationsCardNew from "../integration-tools-card-new"
import "./Integrations.scss"

const Integrations = props => {
  const { heading, buttonText } = props
  const integrations = IntegrationsQuery().SuperOps.seoPosts
  const allIntegrations = [...integrations, ...integrations]
  const layer1 = []
  const layer2 = []
  const layer3 = []
  allIntegrations.map((el, id) => {
    if (id < 5) layer1.push(el)
    else if (id > 4 && id < 11) layer2.push(el)
    else if (id > 10 && id < 15) layer3.push(el)
  })

  const Heading = () => {
    return (
      <Col lg={12} className="left">
        <div className="position-relative mx-auto mb50">
          <Fade bottom duration={1000}>
            <div className="heading position-relative justify-content-center fw-bold">
              {heading}
            </div>
          </Fade>
        </div>
      </Col>
    )
  }
  return (
    <section className="integrations-section-v1">
      <div className="wrapper mx-auto">
        {!props.below && <Heading />}

        <Col lg={12} className="right prelative" style={{ height: "600px" }}>
          <div className="prelative d-flex flex-column integration-parent-wrapper">
            <IntegrationsCardNew data={layer1} integrations />
            <IntegrationsCardNew data={layer2} integrations />
            <IntegrationsCardNew data={layer3} integrations />
          </div>
          <div className="position-absolute line-wrapper"></div>
        </Col>

        {props.below && <Heading />}

        <div className="d-flex justify-content-center mt32 btn-wrapper">
          <Fade bottom duration={1000}>
            <Buttons
              theme="secondary-new"
              link={"/marketplace"}
              text={buttonText ? buttonText : "VIEW THEM ALL"}
            />
          </Fade>
        </div>
      </div>
    </section>
  )
}

export default Integrations

export function IntegrationsQuery() {
  const returneddata = useStaticQuery(graphql`
    query Integrations {
      SuperOps {
        seoPosts(where: { pageName: Integrations }, orderBy: order_ASC) {
          order
          slug
          integrationCards {
            cardName
            subtext
            slug
            image {
              url(transformation: { document: { output: { format: webp } } })
            }
          }
        }
      }
    }
  `)
  return returneddata
}
