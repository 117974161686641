import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import Slide from "../../utility/slide_content"
import "./SimpleBlock.scss"

const SimpleBlock = props => {
  const {
    benefitsTitle,
    benefitsExcerpt,
    benefitsIcon,
    benefitType,
  } = props.data
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  return (
    <div className={`${benefitType} mx-auto`}>
      <Slide delay="200">
        <Container className="Layout-container">
          {benefitType === "simple_blk_v1" && (
            <>
              <div className="p16 text-center content-wrapper mx-auto">
                <h2 className="heading font-roboto fw-bold">{benefitsTitle}</h2>
                <p
                  className={`description ${
                    screenWidth >= 992 ? "mt24" : "mt32"
                  }`}
                >
                  {benefitsExcerpt[0]}
                </p>
              </div>
              <img
                src={
                  screenWidth >= 992 ? benefitsIcon[0].url : benefitsIcon[1].url
                }
                className={`w-100 ${screenWidth <= 992 ? "mt24" : ""}`}
                alt="platform"
              />
            </>
          )}
          {benefitType === "simple_blk_v2" && (
            <div
              className={`${screenWidth <= 992 &&
                "flex-column"} d-flex justify-content-between align-items-end`}
            >
              <Col lg={4}>
                <div className="img-wrapper mb16">
                  <img src={benefitsIcon[0].url} alt="logo" className="w-100" />
                </div>
                <div className="altv3">
                  <h1 className="font-roboto fw-bold m-0">{benefitsTitle}</h1>
                </div>
              </Col>
              <Col lg={7}>
                <p className="p16 content">{benefitsExcerpt}</p>
              </Col>
            </div>
          )}
        </Container>
      </Slide>
    </div>
  )
}

export default SimpleBlock
